import Container from "../Container/Container";
import "./Products.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SoybeanOil from "../../assets/images/soyabeen-oil.png";
import SunflowerOil from "../../assets/images/sunflower-oil.png";
import RapsedOil from "../../assets/images/rapeseed-oil.png";
import SoybeanMeal from "../../assets/images/soybean-meal.jpg";
import SunflowerMeal from "../../assets/images/sunflower meal.png";
import RapeseenMeal from "../../assets/images/rapeseed-meal.png";
import Cake from "../../assets/images/makuha.jpg";
import Sunflower from "../../assets/images/sunflower00.jpg";
import Soi from "../../assets/images/soi.jpg";
import Wheat from "../../assets/images/wheat.jpg";
import Raps from "../../assets/images/raps.jpg";
import Corn from "../../assets/images/corn.jpg";
import { motion } from "framer-motion";
import lang from "../../translations";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowsSize";

const Products = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);
  const { width } = useWindowSize();

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
    }),
  };

  return (
    <motion.section
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="products"
    >
      <Container>
        <div className="products-wrap">
          <motion.h2 custom={2} variants={textAnimation} className="products-title">
            {lang[checkedLang].products_title}
          </motion.h2>
          <motion.p custom={3} variants={textAnimation} className="products-text">
            {lang[checkedLang].products_text}
          </motion.p>
          <div className="products-list">
            <div className="row">
              <motion.h3 custom={4} variants={textAnimation} className="products-subtitle">
                {lang[checkedLang].products_subtitle_1}
              </motion.h3>
              <motion.div style={{ width: "100%" }} custom={5} variants={textAnimation}>
                <Swiper
                  // slidesPerView={width <= 1400 ? 3 : width > 1400 ? 4 : 0}
                  slidesPerView={width >= 1200 ? 4 : width <= 1199 ? 3 : 1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SoybeanOil} title="soybean_oil"  alt="soybean_oil" />
                      <p>{lang[checkedLang].products_item_1}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SunflowerOil} title="sunflower_oil" alt="sunflower_oil" />
                      <p>{lang[checkedLang].products_item_2}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={RapsedOil} title="rapsed_oil" alt="rapsed_oil" />
                      <p>{lang[checkedLang].products_item_3}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SoybeanMeal} title="soybean_meal" alt="soybean_meal" />
                      <p>{lang[checkedLang].products_item_4}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SunflowerMeal} title="sunflower_meal" alt="sunflower_meal" />
                      <p>{lang[checkedLang].products_item_5}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={RapeseenMeal} title="rapsed_meal"  alt="rapsed_meal" />
                      <p>{lang[checkedLang].products_item_6}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Cake} title="rapsed_meal" alt="rapsed_meal" />
                      <p>{lang[checkedLang].products_item_7}</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  // slidesPerView={width <= 1400 ? 3 : width > 1400 ? 4 : 0}
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiperMob"
                >
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SoybeanOil} title="soybean_oil" alt="soybean_oil" />
                      <p>{lang[checkedLang].products_item_1}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SunflowerOil} title="sunflower_oil" alt="sunflower_oil" />
                      <p>{lang[checkedLang].products_item_2}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={RapsedOil} title="rapsed_oil"  alt="rapsed_oil" />
                      <p>{lang[checkedLang].products_item_3}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SoybeanMeal} title="soybean_meal" alt="soybean_meal" />
                      <p>{lang[checkedLang].products_item_4}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={SunflowerMeal} title="sunflower_meal" alt="sunflower_meal" />
                      <p>{lang[checkedLang].products_item_5}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={RapeseenMeal} title="rapsed_meal" alt="rapsed_meal" />
                      <p>{lang[checkedLang].products_item_6}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Cake} title="rapsed_meal" alt="rapsed_meal" />
                      <p>{lang[checkedLang].products_item_7}</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </motion.div>
            </div>
            <div className="row">
              <motion.h3
                custom={6}
                variants={textAnimation}
                className="products-subtitle products-subtitle2"
              >
                {lang[checkedLang].products_subtitle_2}
              </motion.h3>
              <motion.div style={{ width: "100%" }} custom={7} variants={textAnimation}>
                <Swiper
                  slidesPerView={width >= 1200 ? 4 : width <= 1199 ? 3 : 1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Sunflower} title="sunflower" alt="sunflower" />
                      <p>{lang[checkedLang].products_item_8}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Soi} title="soi"  alt="soi" />
                      <p>{lang[checkedLang].products_item_9}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Wheat} title="wheat" alt="wheat" />
                      <p>{lang[checkedLang].products_item_10}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Raps} title="raps" alt="raps" />
                      <p>{lang[checkedLang].products_item_11}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Corn} title="corn"  alt="corn" />
                      <p>{lang[checkedLang].products_item_12}</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiperMob"
                >
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Sunflower} title="sunflower" alt="sunflower" />
                      <p>{lang[checkedLang].products_item_8}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Soi}  title="soi"  alt="soi" />
                      <p>{lang[checkedLang].products_item_9}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Wheat} title="wheat" alt="wheat" />
                      <p>{lang[checkedLang].products_item_10}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Raps} title="raps"  alt="raps" />
                      <p>{lang[checkedLang].products_item_11}</p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="product-item">
                      <img src={Corn} title="corn" alt="corn" />
                      <p>{lang[checkedLang].products_item_12}</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </motion.div>
            </div>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};

export default Products;
