import "./Partners.scss";
import { motion } from "framer-motion";
import lang from "../../translations";
import { useSelector } from "react-redux";
import Container from "../Container/Container";
import Adm from "../../assets/images/adm.png";
import Agroliga from "../../assets/images/al.png";
import Bunge from "../../assets/images/bn.png";
import ChvProt from "../../assets/images/chv-prot.png";
import Dw from "../../assets/images/dw.svg";
import Fl from "../../assets/images/fl.png";
import Gc from "../../assets/images/gc.png";
import Lo from "../../assets/images/lo.svg";
import Ol from "../../assets/images/ol.png";
import Va from "../../assets/images/va.svg";
import Zd from "../../assets/images/zd.png";
import Ks from "../../assets/images/ks.png";

const Partners = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };
  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="partners"
      id="partners"
    >
      <motion.h3 custom={2} variants={textAnimation} className="logistic-title">
        {lang[checkedLang].partners__title}
      </motion.h3>
      <Container>
        <div className="partners-list">
          <div className="partners-item partners-item_1">
            <img src={Adm} alt="adm" />
          </div>
          <div className="partners-item partners-item_2">
            <img src={Agroliga} alt="agroliga" />
          </div>
          <div className="partners-item partners-item_3">
            <img src={Bunge} alt="bunge" />
          </div>
          <div className="partners-item partners-item_4">
            <img src={ChvProt} alt="chv-prot" />
          </div>
          <div className="partners-item partners-item_5">
            <img src={Ks} alt="ks" />
          </div>
          <div className="partners-item partners-item_6">
            <img src={Dw} alt="dw" />
          </div>
          <div className="partners-item partners-item_7">
            <img src={Fl} alt="fl" />
          </div>
          <div className="partners-item partners-item_8">
            <img src={Gc} alt="gc" />
          </div>
          <div className="partners-item partners-item_9">
            <img src={Lo} alt="lo" />
          </div>
          <div className="partners-item partners-item_10">
            <img src={Ol} alt="ol" />
          </div>
          <div className="partners-item partners-item_11">
            <img src={Va} alt="va" />
          </div>
          <div className="partners-item partners-item_12">
            <img src={Zd} alt="zd" />
          </div>
        </div>
      </Container>
    </motion.div>
  );
};

export default Partners;
