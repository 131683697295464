import "./Advantage.scss";
import Container from "../Container/Container";
import Icon_1 from "../../assets/icons/advantage-1.svg";
import Icon_2 from "../../assets/icons/advantage-2.svg";
import Icon_3 from "../../assets/icons/advantage-3.svg";
import Icon_4 from "../../assets/icons/advantage-4.svg";
import Icon_5 from "../../assets/icons/advantage-5.svg";
import Icon_6 from "../../assets/icons/advantage-6.svg";
import Icon_7 from "../../assets/icons/advantage-7.svg";
import Icon_8 from "../../assets/icons/advantage-8.svg";
import { motion } from "framer-motion";
import lang from "../../translations";
import { useSelector } from "react-redux";

const Advantage = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  const textAnimationRight = {
    hidden: (custom) => ({
      x: 200,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  return (
    <motion.section
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="advantage"
      id="advantage"
    >
      <Container>
        <div className="advantage-wrapper">
          <motion.h2 custom={2} variants={textAnimationRight} className="advantage-title">
            {lang[checkedLang].advantage_title}
          </motion.h2>
          <div className="advantage-list">
            <div className="row">
              <motion.div custom={2} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_1} title="advantage-1" alt="advantage-1" />
                </div>
                <p>{lang[checkedLang].advantage_item_1}</p>
              </motion.div>
              <motion.div custom={3} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_2} title="advantage-2" alt="advantage-2" />
                </div>
                <p>{lang[checkedLang].advantage_item_2}</p>
              </motion.div>
              <motion.div custom={4} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_7} title="advantage-7" alt="advantage-7" />
                </div>
                <p>{lang[checkedLang].advantage_item_3}</p>
              </motion.div>
              <motion.div custom={5} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_4} title="advantage-4" alt="advantage-4" />
                </div>
                <p>{lang[checkedLang].advantage_item_4}</p>
              </motion.div>
            </div>
            <div className="row">
              <motion.div custom={6} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_5} title="advantage-5" alt="advantage-5" />
                </div>
                <p>{lang[checkedLang].advantage_item_5}</p>
              </motion.div>
              <motion.div custom={7} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_6} title="advantage-6" alt="advantage-6" />
                </div>
                <p>{lang[checkedLang].advantage_item_6}</p>
              </motion.div>
              <motion.div custom={8} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_3} title="advantage-3" alt="advantage-3" />
                </div>
                <p>{lang[checkedLang].advantage_item_7}</p>
              </motion.div>
              <motion.div custom={9} variants={textAnimation} className="advantage-list__item">
                <div className="item-img__wrapper">
                  <img src={Icon_8} title="advantage-8" alt="advantage-8" />
                </div>
                <p>{lang[checkedLang].advantage_item_8}</p>
              </motion.div>
            </div>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};

export default Advantage;
