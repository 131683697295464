import { useState, useRef, useEffect } from "react";
import Header from "./components/Header/Header";
import Burger from "./components/Burger/Burger";
import Menu from "./components/Burger/Menu";
import Logo from "./assets/images/logo-removebg.png";
import Top from "./components/Top/Top";
import About from "./components/About/About";
import Advantage from "./components/Advantage/Advantage";
import Products from "./components/Products/Products";
import Logistic from "./components/Logistic/Logistic";
import Footer from "./components/Footer/Footer";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Partners from "./components/Partners/Partners";

function App() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const checkedLang = useSelector((state) => state.langReducer.lang);

  return (
    <div className="App">
      {checkedLang === "ua" && (
        <Helmet>
          <title>Soy Commodities - трейдінгова компанія</title>
          <meta charSet="utf-8" />
          <meta
            name="keywords"
            content="SOY COMMODITIES,продаж,купівля,соєва олія,соняшникова олія,ріпакова олія,соєвий шрот,соняшниковий шрот,ріпаковий шрот,соєва макуха,соняшник,соя,ріпак,кукурудза."
          />
          <meta
            name="description"
            content="SOY COMMODITIES - це трейдингова компанія, яка займається поставками: соняшникової, соєвої, рапсової олії і шротів,  соєвої, соняшникової макухи не ГМО/ГМО та експортуємо зернові та бобові культури: кукурудза, соєві боби, ріпак, пшениця."
          />
        </Helmet>
      )}

      {checkedLang === "en" && (
        <Helmet>
          <title>Soy Commodities - trading company </title>
          <meta charSet="utf-8" />
          <meta
            name="keywords"
            content="SOY COMMODITIES, sale, purchase, soybean oil, sunflower oil, rapeseed oil, soybean meal, sunflower meal, rapeseed meal, soybean meal, sunflower, soybean, rapeseed, corn."
          />
          <meta
            name="description"
            content="SOY COMMODITIES is a trading company engaged in the supply of: sunflower, soybean, rapeseed oil and meal, soybean, non-GMO/GMO sunflower cake and we export grain and leguminous crops: corn, soybeans, rapeseed, wheat."
          />
        </Helmet>
      )}

      {checkedLang === "pl" && (
        <Helmet>
          <title>Soy Commodities - jest firmą handlową </title>
          <meta charSet="utf-8" />

          <meta
            name="keywords"
            content="SOY COMMODITIES, sprzedaż, skup, olej sojowy, olej słonecznikowy, olej rzepakowy, śruta sojowa, śruta słonecznikowa, śruta rzepakowa, śruta sojowa, słonecznik, soja, rzepak, kukurydza."
          />
          <meta
            name="description"
            content="SOY COMMODITIES jest firmą handlową zajmującą się dostawą: słonecznika, soi, oleju i śruty rzepakowej, soi, makuchu słonecznikowego non-GMO/GMO oraz eksportujemy zboża i rośliny strączkowe: kukurydzę, soję, rzepak, pszenicę."
          />
        </Helmet>
      )}

      <div ref={node} className="nav-mob">
        <div className="logo">
          <img src={Logo} alt="top-logo" />
        </div>

        <Burger open={open} setOpen={() => setOpen((prev) => !prev)} />
        <Menu open={open} setOpen={() => setOpen((prev) => !prev)} />
      </div>
      <Header />
      <Top />
      <About />
      <Advantage />
      <Products />
      <Partners />
      <Logistic />
      <Footer />
    </div>
  );
}

export default App;
