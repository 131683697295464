import "./BgVideo.scss";
import Video from "../../assets/video/video.mp4";
import { useEffect, useRef } from "react";

const BgVideo = () => {
  const refVideo = useRef();

  const startVideo = () => {
    refVideo.current.autoPlay = true;
  };

  useEffect(() => {
    startVideo();
  }, []);

  return (
    <div className="bg-video">
      <video
        ref={refVideo}
        playsInline
        width="100%"
        height="100%"
        muted
        autoPlay
        preload="auto"
        loop
        src={Video}
        tabIndex="0"
      ></video>
    </div>
  );
};

export default BgVideo;
