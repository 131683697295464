import BgVideo from "../BgVideo/BgVideo";
import { useSelector } from "react-redux";
import Container from "../Container/Container";
import "./Top.scss";
import { motion } from "framer-motion";
import lang from "../../translations";

const Top = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.2 },
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.2 },
    }),
  };

  return (
    <motion.section
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="top"
      id="top"
    >
      <BgVideo />
      <div className="shadow"></div>

      <Container>
        <div className="top-wrapper">
          <motion.p custom={2} variants={textAnimation} className="top-brand">
            {lang[checkedLang].top_title}
          </motion.p>
          <motion.h1
            custom={2}
            variants={textAnimation}
            className="top-title"
            style={lang === "ua" ? { minWidth: "720px" } : { minWidth: "560px" }}
          >
            {lang[checkedLang].top_text}
          </motion.h1>
        </div>
      </Container>
    </motion.section>
  );
};

export default Top;
