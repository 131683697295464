const lang = {
  en: {
    navLink_1: "Home",
    navLink_2: "About us",
    navLink_3: "Products",
    navLink_4: "Partners",
    navLink_5: "Logistics",
    navLink_6: "Contacts",
    top_title: "SOY COMMODITIES",
    top_text:
      "Improve your business and expand your horizons with us.\n Reliable purchase and sale of agricultural products only with trusted partners.",
    about_title: "ABOUT THE COMPANY",
    about_text_1:
      "exports the best agricultural products of cereals and legumes, as well as processed products grown and manufactured by our group of companies in Ukraine to more than 25 countries worldwide.",
    about_text_2: "We guarantee high quality, service, timely logistics, and the best prices.",
    about_text_3:
      "The team of Soy Commodities has already established strong and long-term partnership relations with many European companies. We invite you to reliable cooperation.",
    advantage_title: "ADVANTAGES",
    advantage_item_1: "High quality of products",
    advantage_item_2: "High level of service",
    advantage_item_3: "Best prices",
    advantage_item_4: "Timely logistics",
    advantage_item_5: "Communication with clients and partners 24/7",
    advantage_item_6: "Reliable reputation",
    advantage_item_7: "Assistance in finding new directions",
    advantage_item_8: "Loading on FCA/DAP/CPT/FOB/CIF terms",
    partners__title: "OUR PARTNERS",
    products_title: "Products",
    products_text:
      "We specialize in the supply of sunflower / soybean / rapeseed oil and meal, as well as non-GMO / GMO soybean / sunflower meal. We also export grains and legumes such as corn, soybeans, rapeseed, and wheat.",
    products_subtitle_1: "Processed products",
    products_subtitle_2: "Grains and legumes",
    products_item_1: "Soybean oil",
    products_item_2: "Sunflower oil",
    products_item_3: "Rapeseed oil",
    products_item_4: "Soybean meal",
    products_item_5: "Sunflower meal",
    products_item_6: "Rapeseed meal",
    products_item_7: "Soybean cake",
    products_item_8: "Sunflower",
    products_item_9: "Soybean",
    products_item_10: "Wheat",
    products_item_11: "Rapeseed",
    products_item_12: "Corn",
    logistic_title: "LOGISTICS",
    logistic_text: "We carry out shipments on the basis of FCA, DAP, CPT, FOB, CIF.",
    logistic_item_1: "Road transportation",
    logistic_item_2: "Rail transportation",
    logistic_item_3: "Sea transportation",
    footer_nav_title: "Navigation",
    footer_nav_item_1: "Home",
    footer_nav_item_2: "About Us",
    footer_nav_item_3: "Advantages",
    footer_nav_item_4: "Partners",
    footer_nav_item_5: "Logistics",
    footer_nav_item_6: "Products",
    footer_contact_title: "Contacts",
    footer_contact_item_1: "soycommodities@gmail.com",
    footer_contact_item_2: "+38 066 468 04 79",
    footer_contact_item_3: "+38 095 930 76 61",
    footer_contact_name_1: "Grains and legumes",
    footer_contact_name_2: "Processed products",
  },
  ua: {
    navLink_1: "Головна",
    navLink_2: "Про нас",
    navLink_3: "Продукція",
    navLink_4: "Партнери",
    navLink_5: "Логістика",
    navLink_6: "Контакти",
    top_title: "SOY COMMODITIES",
    top_text:
      "Вдосконалюйте свій бізнес та розширюйте напрямки разом з нами.\n Надійна купівля та продаж сільсько-господарської продукції тільки з надійними партнерами",
    about_title: "ПРО КОМПАНІЮ",
    about_text_1:
      "експортує у більш ніж 25 країн світу найкращу аграрну продукцію зернових та бобових культур, а також продуктів переробки вирощену та виготовлену нашою групою компаній в Україні.",
    about_text_2: "Ми гарантуємо високу якість, сервіс, своечасну логістику та найкращі ціни.",
    about_text_3:
      "Комада Soy Commodities вже побудувала с багатьма европейськими компаніями міцні та довгострокові партнерські відносини. Та запрошуемо Вас до надійної співпраці.",
    advantage_title: "ПЕРЕВАГИ",
    advantage_item_1: "ВИСОКА ЯКІСТЬ ПРОДУКЦІЇ",
    advantage_item_2: "ВИСОКИЙ СЕРВІС",
    advantage_item_3: "НАЙКРАЩІ ЦІНИ",
    advantage_item_4: "СВОЄЧАСНА ЛОГІСТИКА",
    advantage_item_5: "КОМУНІКАЦІЯ З КЛІЄНТАМИ ТА ПАРТНЕРАМИ 24/7",
    advantage_item_6: "НАДІЙНА РЕПУТАЦІЯ",
    advantage_item_7: "ДОПОМОГА В ЗНАХОДЖЕННІ НОВИХ НАПРЯМІВ",
    advantage_item_8: "ВІДВАНТАЖЕННЯ НА БАЗИСАХ FCA/DAP/CPT/FOB/CIF",
    partners__title: "НАШІ ПАРТНЕРИ",
    products_title: "ПРОДУКЦІЯ",
    products_text:
      "Ми займаємося поставками соняшникової / соєвої / ріпакової олії / і шротів, і так само соєвої / соняшникової макухи не ГМО / ГМО. Також експортуємо зернові та бобові культури (кукурудза, соєві боби, ріпак, пшениця).",
    products_subtitle_1: "ПРОДУКТИ ПЕРЕРОБКИ",
    products_subtitle_2: "ЗЕРНОВІ ТА БОБОВІ КУЛЬТУРИ",
    products_item_1: "Соєва олія",
    products_item_2: "Соняшникова олія",
    products_item_3: "Ріпакова олія",
    products_item_4: "Соєвий шрот",
    products_item_5: "Соняшниковий шрот",
    products_item_6: "Ріпаковий шрот",
    products_item_7: "Соєва макуха",
    products_item_8: "Соняшник",
    products_item_9: "Соя",
    products_item_10: "Пшениця",
    products_item_11: "Ріпак",
    products_item_12: "Кукурудза",
    logistic_title: "ЛОГІСТИКА",
    logistic_text: "Здійснюємо відвантаження на базисі FCA, DAP, СРТ, FOB, СIF",
    logistic_item_1: "Авто перевезення",
    logistic_item_2: "З/Д перевезення",
    logistic_item_3: "Морські перевезення",
    footer_nav_title: "Навігація",
    footer_nav_item_1: "Головна",
    footer_nav_item_2: "Про нас",
    footer_nav_item_3: "Переваги",
    footer_nav_item_4: "Партнери",
    footer_nav_item_5: "Логістика",
    footer_nav_item_6: "Продукція",
    footer_contact_title: "Контакти",
    footer_contact_item_1: "soycommodities@gmail.com",
    footer_contact_item_2: "+38 066 468 04 79",
    footer_contact_item_3: "+38 095 930 76 61",
    footer_contact_name_1: "Зернові та бобові культури",
    footer_contact_name_2: "Продукти переробки",
  },
  pl: {
    navLink_1: "Główna",
    navLink_2: "O nas",
    navLink_3: "Produkcja",
    navLink_4: "Wzmacniacz",
    navLink_5: "Logistyka",
    navLink_6: "Kontakt",
    top_title: "SOY COMMODITIES",
    top_text:
      "Poprawić swój biznes i poszerzaj obszary razem z nami. Pewne zakupy i sprzedaż produktów rolnych tylko z solidnymi partnerami.",
    about_title: "O FIRMIE",
    about_text_1:
      "eksportuje do ponad 25 krajów na świecie najlepsze produkty rolne, takie jak zboża i strączkowe, a także przetworzone produkty uprawiane i produkowane przez naszą grupę firm na Ukrainie.",
    about_text_2: "Zapewniamy wysoką jakość, obsługę, terminową logistykę i najlepsze ceny.",
    about_text_3:
      "Zespół Soy Commodities zbudował już solidne i długoterminowe partnerstwa z wieloma europejskimi firmami. Zapraszamy do solidnej współpracy.",
    advantage_title: "ZALETY",
    advantage_item_1: "WYSOKA JAKOŚĆ PRODUKTÓW",
    advantage_item_2: "WYSOKA OBSŁUGA KLIENTA",
    advantage_item_3: "NAJLEPSZE CENY",
    advantage_item_4: "TERMINOWA LOGISTYKA",
    advantage_item_5: "KOMUNIKACJA Z KLIENTAMI I PARTNERAMI 24/7",
    advantage_item_6: "SOLIDNA REPUTACJA",
    advantage_item_7: "POMOC W ZNAJDOWANIU NOWYCH KIERUNKÓW",
    advantage_item_8: "WYSYŁKA NA WARUNKACH FCA/DAP/CPT/FOB/CIF",
    partners__title: "NASI PARTNERZY",
    products_title: "PRODUKCJA",
    products_text:
      "Zajmujemy się dostarczaniem oleju słonecznikowego / sojowego / rzepakowego i śrutu, a także śrutu sojowego / słonecznikowego, niewiążącego GMO / GMO. Eksportujemy również zboża i strączkowe (kukurydza, ziarno soi, rzepak, pszenica).",
    products_subtitle_1: "PRODUKTY PRZETWÓRCZE",
    products_subtitle_2: "ZBOŻA I STRĄCZKOWE",
    products_item_1: "Olej sojowy",
    products_item_2: "Olej słonecznikowy",
    products_item_3: "Olej rzepakowy",
    products_item_4: "Śrut sojowy",
    products_item_5: "Śrut słonecznikowy",
    products_item_6: "Śrut rzepakowy",
    products_item_7: "Makuch sojowy",
    products_item_8: "Słonecznik",
    products_item_9: "Soja",
    products_item_10: "Pszenica",
    products_item_11: "Rzepak",
    products_item_12: "Kukurydza",
    logistic_title: "LOGISTYKA",
    logistic_text: "Dokonujemy wysyłek na warunkach FCA, DAP, CPT, FOB, CIF",
    logistic_item_1: "Transport drogowy",
    logistic_item_2: "Transport kolejowy",
    logistic_item_3: "Transport morski",
    footer_nav_title: "Nawigacja",
    footer_nav_item_1: "Główna",
    footer_nav_item_2: "O nas",
    footer_nav_item_3: "Zalety",
    footer_nav_item_4: "Wzmacniacz",
    footer_nav_item_5: "Logistyka",
    footer_nav_item_6: "Produkcja",
    footer_contact_title: "Kontakt",
    footer_contact_item_1: "soycommodities@gmail.com",
    footer_contact_item_2: "+38 066 468 04 79",
    footer_contact_item_3: "+38 095 930 76 61",
    footer_contact_name_1: "Zboża i rośliny strączkowe",
    footer_contact_name_2: "Produkty przetworzone",
  },
};

export default lang;
