import Container from "../Container/Container";
import "./About.scss";
import Brand from "../../assets/images/logo-removebg.png";
import { motion } from "framer-motion";
import lang from "../../translations";
import { useSelector } from "react-redux";

const About = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  const textAnimationLeft = {
    hidden: (custom) => ({
      x: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  const textAnimationRight = {
    hidden: (custom) => ({
      x: 200,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      x: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  return (
    <motion.section
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="about"
      id="about"
    >
      <Container>
        <motion.h2 custom={2} variants={textAnimation} className="about-title">
          {lang[checkedLang].about_title}
        </motion.h2>
        <motion.div className="about-descr">
          <motion.div custom={4} variants={textAnimationLeft} className="about-content">
            <p>
              <span>Soy Commodities </span>
              {lang[checkedLang].about_text_1}
            </p>
            <p>{lang[checkedLang].about_text_2}</p>
            <p>{lang[checkedLang].about_text_3}</p>
          </motion.div>
          <motion.div custom={4} variants={textAnimationRight} className="about-img">
            <img src={Brand} title='about-logo' alt="brand-logo" />
          </motion.div>
        </motion.div>
      </Container>
    </motion.section>
  );
};

export default About;
