import Container from "../Container/Container";
import "./Footer.scss";
import FooterLogo from "../../assets/images/logo-removebg.png";
import { Link } from "react-scroll";
import lang from "../../translations";
import { useSelector } from "react-redux";
import { ReactComponent as EmailIcon } from "../../assets/images/email-1-svgrepo-com.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-svgrepo-com.svg";

const Footer = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  return (
    <footer className="footer" id="contacts">
      <Container>
        <div className="footer-wrapper">
          <div className="footer-img">
            <img src={FooterLogo} title='footer-logo' alt="footer-logo" />
          </div>
          <div className="footer-navigation">
            <p className="footer-navigation__title">{lang[checkedLang].footer_nav_title}</p>
            <Link
              to="top"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_1}
            </Link>
            <Link
              to="about"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_2}
            </Link>
            <Link
              to="advantage"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_3}
            </Link>
            <Link
              to="logistic"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_4}
            </Link>
            <Link
              to="partners"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_5}
            </Link>
            <Link
              to="products"
              duration={500}
              smooth={true}
              target="_blank"
              className="footer-navigation__link"
            >
              {lang[checkedLang].footer_nav_item_6}
            </Link>
          </div>
          <div className="footer-contacts">
            <p className="footer-contacts__title">{lang[checkedLang].footer_contact_title}</p>
            <div className="footer-contact_item">
              <EmailIcon />
              <a href="mailto:soycommodities@gmail.com" className="footer-contacts__link">
                {lang[checkedLang].footer_contact_item_1}
              </a>
            </div>
            <div className="footer-contact_item">
              <PhoneIcon />
              <a href="tel:+380664680479" className="footer-contacts__link">
                {lang[checkedLang].footer_contact_item_2}
                <span>{lang[checkedLang].footer_contact_name_1}</span>
              </a>
            </div>
            <div className="footer-contact_item">
              <PhoneIcon />
              <a href="tel:+380959307661" className="footer-contacts__link">
                {lang[checkedLang].footer_contact_item_3}
                <span>{lang[checkedLang].footer_contact_name_2}</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
