import { createReducer } from "@reduxjs/toolkit";

const initialState = { lang: "en" };

const langReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("UA", (state) => {
      state.lang = "ua";
    })
    .addCase("EN", (state) => {
      state.lang = "en";
    })
    .addCase("PL", (state) => {
      state.lang = "pl";
    });
});

export default langReducer;
