import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ReactComponent as UaIcon } from "../../assets/images/uaBig.svg";
import { ReactComponent as EnIcon } from "../../assets/images/enBig.svg";
import { ReactComponent as PlIcon } from "../../assets/images/plBig.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email-burger.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-burger.svg";
import lang from "../../translations";
import { Link } from "react-scroll";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #effffa;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(200%)")};
  height: 100vh;
  text-align: left;
  padding: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  width: 100%;
  height: 100%;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.3rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;
    margin-bottom: 15px;
    text-align: center !important;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`;

const Menu = ({ open, setOpen }) => {
  const checkedLang = useSelector((state) => state.langReducer.lang);
  const dispatch = useDispatch();

  const onChangeLangHandler = (checked) => {
    dispatch({ type: checked });
    localStorage.setItem("lang", checked);
  };

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      dispatch({ type: localStorage.getItem("lang") });
    }
  }, []);

  return (
    <StyledMenu open={open}>
      <div className="flags">
        <EnIcon
          onClick={() => {
            onChangeLangHandler("EN");
          }}
        />
        <PlIcon
          onClick={() => {
            onChangeLangHandler("PL");
          }}
        />
        <UaIcon
          onClick={() => {
            onChangeLangHandler("UA");
          }}
        />
      </div>
      <nav className="navigation">
        <Link to="top" onClick={setOpen} duration={500} smooth={true} target="_blank">
          {lang[checkedLang].navLink_1}
        </Link>
        <Link
          to="about"
          onClick={setOpen}
          offset={-70}
          duration={500}
          smooth={true}
          target="_blank"
        >
          {lang[checkedLang].navLink_2}
        </Link>
        <Link
          to="products"
          onClick={setOpen}
          offset={-70}
          duration={500}
          smooth={true}
          target="_blank"
        >
          {lang[checkedLang].navLink_3}
        </Link>
        <Link
          to="partners"
          onClick={setOpen}
          offset={-70}
          duration={500}
          smooth={true}
          target="_blank"
        >
          {lang[checkedLang].navLink_4}
        </Link>
        <Link
          to="logistic"
          onClick={setOpen}
          offset={-70}
          duration={500}
          smooth={true}
          target="_blank"
        >
          {lang[checkedLang].navLink_5}
        </Link>
        <Link to="contacts" onClick={setOpen} duration={500} smooth={true} target="_blank">
          {lang[checkedLang].navLink_6}
        </Link>
      </nav>

      <div className="burger-contacts">
        <div className="burger-contacts_wrapper">
          <span className="burger-contacts_item">
            <EmailIcon />
            <a href="mailto:soycommodities@gmail.com">soycommodities@gmail.com</a>
          </span>

          <span className="burger-contacts_item">
            <PhoneIcon />
            <a href="tel:+380664680479">+380664680479</a>
          </span>
        </div>
      </div>
    </StyledMenu>
  );
};

export default Menu;
