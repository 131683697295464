import Container from "../Container/Container";
import "./Header.scss";
import Logo from "../../assets/images/logo-removebg.png";
import { useEffect } from "react";
import { ReactComponent as UaIcon } from "../../assets/images/ua.svg";
import { ReactComponent as EnIcon } from "../../assets/images/en.svg";
import { ReactComponent as PlIcon } from "../../assets/images/pl.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email-1-svgrepo-com.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone-svgrepo-com.svg";
import { useDispatch, useSelector } from "react-redux";
import lang from "../../translations";
import { Link } from "react-scroll";

const Header = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);
  const dispatch = useDispatch();

  const onChangeLangHandler = (checked) => {
    dispatch({ type: checked });
    localStorage.setItem("lang", checked);
  };

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      dispatch({ type: localStorage.getItem("lang") });
    }
  }, []);

  return (
    <>
      <header className="header">
        <Container>
          <div className="header-wrap">
            <div className="logo">
              <img src={Logo} alt="top-logo" />
            </div>
            <nav className="navigation">
              <Link to="top" duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_1}
              </Link>
              <Link to="about" offset={-70} duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_2}
              </Link>
              <Link to="products" offset={-70} duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_3}
              </Link>
              <Link to="partners" offset={-70} duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_4}
              </Link>
              <Link to="logistic" offset={-70} duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_5}
              </Link>
              <Link to="contacts" duration={500} smooth={true} target="_blank">
                {lang[checkedLang].navLink_6}
              </Link>
            </nav>
            <div className="flags">
              <EnIcon alt="en" onClick={() => onChangeLangHandler("EN")} />
              <PlIcon alt="pl" onClick={() => onChangeLangHandler("PL")} />
              <UaIcon alt="ua" onClick={() => onChangeLangHandler("UA")} />
            </div>
          </div>
        </Container>
      </header>
      <div className="contacts">
        <div className="contacts-wrap">
          <span className="contact-item_1 contact-item">
            <EmailIcon alt="icon-email" />
            <a href="mailto:soycommodities@gmail.com">soycommodities@gmail.com</a>
          </span>

          <span className="contact-item_3 contact-item">
            <PhoneIcon alt="icon-phone" />
            <a href="tel:+380664680479">+38 066 468 04 79</a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Header;
