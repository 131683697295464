import Container from "../Container/Container";
import "./Logistic.scss";
import LogisticImg_1 from "../../assets/images/delivery-2.png";
import LogisticImg_2 from "../../assets/images/delivery-3.png";
import LogisticImg_3 from "../../assets/images/delivery-5.png";
import { motion } from "framer-motion";
import lang from "../../translations";
import { useSelector } from "react-redux";

const Logistic = () => {
  const checkedLang = useSelector((state) => state.langReducer.lang);

  const textAnimation = {
    hidden: (custom) => ({
      y: -100,
      opacity: 0,
      transition: { delay: custom * 0.1 },
      // display: "none",
    }),
    visible: (custom) => ({
      y: 0,
      opacity: 1,
      transition: { delay: custom * 0.1 },
      zIndex: 0,
      // display: "block",
    }),
  };

  return (
    <motion.div
      animate={{ opacity: 1 }}
      initial="hidden"
      whileInView="visible"
      className="logistic"
      id="logistic"
    >
      <motion.h3 custom={2} variants={textAnimation} className="logistic-title">
        {lang[checkedLang].logistic_title}
      </motion.h3>
      <motion.p custom={3} variants={textAnimation} className="logistic-text">
        {lang[checkedLang].logistic_text}
      </motion.p>
      <Container>
        <div className="logistic-wrap">
          <motion.div custom={4} variants={textAnimation} className="logistic-card">
            <img src={LogisticImg_1} title="auto" alt="logistic-1" />
            <div>{lang[checkedLang].logistic_item_1}</div>
          </motion.div>
          <motion.div custom={5} variants={textAnimation} className="logistic-card">
            <img src={LogisticImg_2} title='train' alt="logistic-2" />
            <div>{lang[checkedLang].logistic_item_2}</div>
          </motion.div>
          <motion.div custom={6} variants={textAnimation} className="logistic-card">
            <img src={LogisticImg_3} title='sea' alt="logistic-3" />
            <div>{lang[checkedLang].logistic_item_3}</div>
          </motion.div>
        </div>
      </Container>
    </motion.div>
  );
};

export default Logistic;
